import { IFormData, PrognosticsMode, SynastryMode } from 'src/libs';
import { makeAutoObservable, observable, runInAction } from 'mobx';
import { TablePredictionProps } from 'src/pages/Instruments/Widgets/TablePrediction/TablePrediction';
import { ITablePredictionSettings } from 'src/pages/Instruments/Widgets/TablePrediction/utils';

export type TPrognosticsKey = 'prog_prog' | 'directions' | 'solars' | 'transits' | string;
export type TPartnerKey = 'partner1' | 'partner2' | 'partner3' | 'partner4' | 'partner5' | 'partner6' | 'partner7' | 'partner8' | 'partner9' | string;
export type TCompatibilityKey = 'compatibility1' | 'compatibility2' | 'compatibility3' | 'compatibility4' | 'compatibility5' | 'compatibility6' | 'compatibility7' | 'compatibility8' | 'compatibility9' | string;

export interface IPrognosticsMapsState { [key: TPrognosticsKey]: boolean };
export interface ISynastryMapsState { [key: TCompatibilityKey]: boolean };

export enum ECompatibilityTransitMode {
  MODE_1 = 1,
  MODE_2 = 2,
}

export enum EInitSynastryTransitsCommand {
  ADD = 1,
  DELETE = 2,
  NONE = 0,
}

export interface ICompatibilityTransits {
  [key: TCompatibilityKey]: { showTransit: boolean; transitMode: ECompatibilityTransitMode };

}

const prognosticsMapIndicatorsDefault = (): IPrognosticsMapsState => ({ 'directions': true, 'solars': true, 'transits': true, 'prog_natal': true });
const synastryMapIndicatorsDefault = (): ISynastryMapsState => ({
  'partner1': true,
  'partner2': true,
  'partner3': true,
  'partner4': true,
  'partner5': true,
  'partner6': true,
  'partner7': true,
  'partner8': true,
  'partner9': true,
  'compatibility1': true,
  'compatibility2': true,
  'compatibility3': true,
  'compatibility4': true,
  'compatibility5': true,
  'compatibility6': true,
  'compatibility7': true,
  'compatibility8': true,
  'compatibility9': true,
});



export interface IMapIndicatorState {
  compatibilityReversed: boolean;

  prognosticsExt: IPrognosticsMapsState;
  prognosticsNatal: IPrognosticsMapsState;

  partnerExt: ISynastryMapsState;
  partnerNatal: ISynastryMapsState;

  compatibilityTransits: ICompatibilityTransits

  initSynastryTransits: EInitSynastryTransitsCommand;

}

export interface ItablePredictionSettings {
  result: TablePredictionProps["tablePredictionSettings"]
}

export default class InstrumentsStore {

  private mapIndicator: IMapIndicatorState = observable({
    compatibilityReversed: false,

    compatibilityTransits: {
      'compatibility1': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
      'compatibility2': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
      'compatibility3': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
      'compatibility4': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
      'compatibility5': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
      'compatibility6': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
      'compatibility7': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
      'compatibility8': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
      'compatibility9': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
    },

    initSynastryTransits: EInitSynastryTransitsCommand.NONE,

    prognosticsExt: prognosticsMapIndicatorsDefault(), // for multipla maps
    prognosticsNatal: prognosticsMapIndicatorsDefault(),

    partnerExt: synastryMapIndicatorsDefault(),
    partnerNatal: synastryMapIndicatorsDefault(),

  });

  constructor() {
    makeAutoObservable(this);
  }

  widgetsTab: string | null = null;
  formData: IFormData | null = null;

  private _updateMapIndicator(mapIndicator: IMapIndicatorState): void {
    runInAction(() => {
      this.mapIndicator = JSON.parse(JSON.stringify(mapIndicator));
    })
  }

  tablePredictionSettings: { [formId: number]: ItablePredictionSettings } = observable({});
  setTablePredictionSettings(formId: number, value: any): void {
    runInAction(() => {
      if (!this.tablePredictionSettings[formId]) {
        this.tablePredictionSettings[formId] = { result: {} };
      }
      this.tablePredictionSettings[formId].result = { ...value };
    });
  }

  tablePredictionSettingsForm: { [formId: number]: ITablePredictionSettings } = observable({});
  setTablePredictionSettingsForm(formId: number, value: any): void {
    runInAction(() => {
      this.tablePredictionSettingsForm[formId] = { ...value };
    });
  }

  openTablePredictionSettings: { [formId: number]: { value: boolean, previousValue: boolean | undefined } } = observable({});
  setOpenTablePredictionSettings(formId: number, value: any): void {
    // console.log(`---store---OpenTPS---formId:${formId}, value:${value}`);

    const currentValue = this.openTablePredictionSettings[formId]?.value;
    runInAction(() => {
      this.openTablePredictionSettings[formId] = { value, previousValue: currentValue };
    });
  }

  openTablePrediction: { [formId: number]: { value: boolean } } = observable({});
  setOpenTablePrediction(formId: number, value: any): void {
    // console.log(`---store---openTP---formId:${formId}, value:${value}`);

    runInAction(() => {
      this.openTablePrediction[formId] = { value };
    });
  }

  isCountUpdateBlocked: boolean = false;

  countCalcTablePrediction: { value: number } = observable({ value: 0 });
  setCountCalcTablePrediction(value: number): void {
    if (this.isCountUpdateBlocked) {
      return;
    }
    // console.log(`---store---countCalcTP---value:${value}`);

    runInAction(() => {
      this.countCalcTablePrediction = { value };
      this.countCalcTablePrediction = { ...this.countCalcTablePrediction }
    })
  }

  startStopCalcTablePrediction: { [formId: number]: { value: boolean } } = observable({});
  setstartStopCalcTablePrediction(formId: number, value: boolean): void {
    // console.log(`---store---startStopCalcTP---formId:${formId}, value:${value}`);

    runInAction(() => {
      this.startStopCalcTablePrediction[formId] = { value };
    });
  }


  setMapCompatibilityReversed(value: boolean): void {
    this.mapIndicator.compatibilityReversed = value;
    this._updateMapIndicator(this.mapIndicator);
  }

  setMapIndicatorPrognosticsExt(value: boolean, mapMode: PrognosticsMode): void {
    (this.mapIndicator.prognosticsExt as any)[mapMode] = value;
    this._updateMapIndicator(this.mapIndicator);
  }

  setMapIndicatorPrognosticsNatal(value: boolean, mapMode: PrognosticsMode): void {
    (this.mapIndicator.prognosticsNatal as any)[mapMode] = value;
    this._updateMapIndicator(this.mapIndicator);
  }

  setMapIndicatorPartnerExt(mode: SynastryMode, value: boolean, mapIndex: number): void {
    this.mapIndicator.partnerExt[mode] = value;
    this._updateMapIndicator(this.mapIndicator);
  }

  setMapIndicatorPartnerNatal(mode: SynastryMode, value: boolean, mapIndex: number): void {
    this.mapIndicator.partnerNatal[mode] = value;
    this._updateMapIndicator(this.mapIndicator);
  }

  setCompatibilityTransitMode(mode: ECompatibilityTransitMode, forMapMode: TCompatibilityKey): void {
    this.mapIndicator.compatibilityTransits[forMapMode].transitMode = mode;
    this._updateMapIndicator(this.mapIndicator);
  }

  setCompatibilityTransitShow(show: boolean, forMapMode: TCompatibilityKey): void {
    this.mapIndicator.compatibilityTransits[forMapMode].showTransit = show;
    this._updateMapIndicator(this.mapIndicator);
  }

  setInitSynastryTransits(value: EInitSynastryTransitsCommand): void {
    this.mapIndicator.initSynastryTransits = value;
    this._updateMapIndicator(this.mapIndicator);
  }

  resetSynastryTransits() {

    for (let key in this.mapIndicator.compatibilityTransits) {
      this.mapIndicator.compatibilityTransits[key].showTransit = true;
      this.mapIndicator.compatibilityTransits[key].transitMode = ECompatibilityTransitMode.MODE_1;
    }

    this._updateMapIndicator(this.mapIndicator);
  }

  get mapIndicatorCurrent(): IMapIndicatorState {
    return this.mapIndicator;
  }

  setFormData(data: Partial<IFormData>) {
    this.formData = {
      ...(this.formData ?? {} as IFormData),
      ...data,
    };
    localStorage.setItem('formData', JSON.stringify(data));
  }

  getLSFormData() {
    if (this.formData) {
      return this.formData;
    } else {
      const formDataJSON = localStorage.getItem('formData');
      if (formDataJSON) {
        return JSON.parse(formDataJSON);
      }
    }
  }

}
