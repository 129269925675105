import { observer } from 'mobx-react';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import _ from 'lodash';

import { isPrognostics, isRelocation, CircleMode, IFormData, IMap, isSynastry, ISynastryPartnerData, fitToRange } from 'src/libs';

import { checkActiveInput, debugRender } from 'src/hooks';

import Circle, { IHighlightsData } from '../../components/Circle';
import { IStrongs, WidgetTypes } from './Widgets/Widget';
import { IHighlight } from '.';
import { hasExt, highlightAspect } from './Widgets/AspectsTable/utils';
import store from 'src/store';
import Horar from '../../components/Circle/Horar';

import { MapPreloader } from './Preloaders';
import { IPoint } from 'src/api';
import { MinorAspectsModes } from 'src/components/Circle/Aspects';
import { getDeviceDetect } from 'src/utils';
import dispatcher from 'src/dispatcher';
import MapIndicator from 'src/components/MapIndicator';
import { isCompatibility } from './utils';
import { TWidgetCircleMode } from './Widgets/AspectsTable/ModeSelectorTabs';

import Theme from 'src/theme';
import StarSvgWhite from 'src/assets/illustrations/instruments/star-white.svg';
import StarSvgDark from 'src/assets/illustrations/instruments/star-dark.svg';

import Star2SvgWhite from 'src/assets/illustrations/instruments/star2-white.svg';
import Star2SvgDark from 'src/assets/illustrations/instruments/star2-dark.svg';

import Star3SvgWhite from 'src/assets/illustrations/instruments/star3-white.svg';
import Star3SvgDark from 'src/assets/illustrations/instruments/star3-dark.svg';
import { ECompatibilityTransitMode } from 'src/store/InstrumentsStore';
import aspect from './Widgets/Prompts/data/aspects/Binovile';

const { isTablet } = getDeviceDetect();

export function showMapInfoPopup(el: Element, lines: string[], mouseMove: MouseEvent) {
  if (!el) return;
  const objR = el.getBoundingClientRect();

  const info = document.querySelector('#map-info-popup') as HTMLDivElement;

  if (!info) return;

  const fragment = document.createDocumentFragment();

  info.style.display = 'flex';

  for (let i = 0; i < lines.length; i++) {
    const line = document.createElement('div');
    line.innerHTML = lines[i];
    fragment.append(line);
  }

  info.innerHTML = '';
  info.appendChild(fragment);

  const infoR = info.getBoundingClientRect();

  const regexp = /Firefox/
  const isFirefox = regexp.test(navigator.userAgent)

  if (isFirefox) {
    info.style.left = `${mouseMove.clientX - infoR.width - 16}px`;
    info.style.top = `${mouseMove.clientY}px`;
  } else {
    info.style.left = `${mouseMove.clientX - infoR.width - 16}px`;
    info.style.top = `${mouseMove.clientY}px`;
  }

  info.style.zIndex = '2'
}

export function hideMapInfoPopup() {
  const info = document.querySelector('#map-info-popup') as HTMLDivElement;

  if (!info) { return }

  info.style.display = 'none';
}

const getActiveModesKeys = (auxModes: CircleMode[], modes: CircleMode[]): number[] => {
  const keys: number[] = [];
  auxModes.forEach((mode, i) => {
    if (modes.includes(mode as CircleMode)) {
      keys.push(i + 1);
    }
  });
  return keys;
};

export interface IExtendedMap extends IMap {
  name?: string
}

@observer
export default class Maps extends React.Component<{
  form?: IFormData;
  maps: IExtendedMap[];
  strongs: IStrongs;
  natal: IMap | null;
  highlights: IHighlightsData;
  pinnedAspects: number[];
  currentMode: string;
  widgetMode: TWidgetCircleMode;
  isActiveWidgets?: { id: WidgetTypes, isActive?: string | boolean }[];
  prognosticsModes: CircleMode[];
  synastryModes: CircleMode[];
  relocationModes: CircleMode[];
  onChanged(key: string, value: any): void;
  onModeChanged?(mode: CircleMode | CircleMode[]): void;
  auxModes: CircleMode[];
  minorAspectsMode: MinorAspectsModes;
  t?: any;
  countMapScale: (ref: React.RefObject<HTMLDivElement>) => void;
  setScale: (newScale: number) => void;
  scale: number;
  minSide: number;
  onHold(id: any, value: boolean): void;
  mapSize: number;
  finalScale: number;
  partners?: ISynastryPartnerData[]
  relocation: any;
  limitedAccess: boolean;
  limitedAccessAction?: () => void;
}, {}> {
  state = {
    gestureRelativeScale: 1,
    gestureChangeLock: true,
    shift: 0,
  };

  private readonly _container = React.createRef<HTMLDivElement>();
  private readonly _map = React.createRef<SVGSVGElement>();
  private readonly _circle = React.createRef<SVGSVGElement>();
  private readonly _scroll = React.createRef<HTMLDivElement>();

  private readonly _remC: number = 16;

  private _mapDiameter: number = 0;
  private _scrolling = false;
  private _moving = false;
  private _spacePressed = false;
  private _zoomPoint: IPoint | null = null;
  private _keyPressed = false;

  private readonly personalization = store.settings.personalization.current;
  private readonly computedRadius = store.settings.personalization.computedRadius;

  private _keyScroll = false;
  private _timeoutId: number | null = null;

  private _modes: CircleMode[] = [];

  private _prevScroll = {
    left: 0,
    top: 0,
    width: 0,
    height: 0
  };

  private handleResize = () => this.scrollToTablet(this.props.currentMode as CircleMode || this.props.maps[0].mode, false, true);

  componentDidUpdate(prevProps: any) {
    this.updateModes(this.props.maps);

    const cont = this._container.current as HTMLDivElement;

    if (cont && this._zoomPoint) {
      const { left, top, width, height } = this._prevScroll;
      const { x, y } = this._zoomPoint;

      const w2 = cont.clientWidth / 2;
      const h2 = cont.clientHeight / 2;

      cont.scrollTo(
        x + (left + w2) * cont.scrollWidth / width - w2,
        y + (top + h2) * cont.scrollHeight / height - h2
      );

      this._zoomPoint = null;
    }

    if (prevProps.isActiveWidgets.length != this.props.isActiveWidgets?.length) {
      this.centerMaps();
    }

    if (isTablet && (this.props.maps.length && prevProps.maps.length && this.props.maps.length !== prevProps.maps.length)) {
      this.handleResize();
    }

  }

  centerMaps = () => {
    const cont = this._container.current as HTMLDivElement;

    if (cont) {
      setTimeout(() => {
        const { radius: { circle: radius }, gap } = store.settings.personalization.current.circle;
        const diameter = radius * 2;
        const mapWithTextsWidth = (diameter + gap) * this.props.scale * this.props.finalScale;
        const isActiveWidget = (this.props.isActiveWidgets?.length === 1 && (this.props.isActiveWidgets[0].id as string) === 'table-prediction') ? 0 : this.props.isActiveWidgets?.length;
        const widgetWidth = isActiveWidget
          ? document.getElementById('widget-container')?.clientWidth || 0
          : 0;
        const sideBarWidth = document.getElementById('side-bar-container')?.clientWidth || 0;
        const windowWidth = document.documentElement!.clientWidth;
        const viewWidth = windowWidth - widgetWidth - sideBarWidth;
        const visibleMaps = mapWithTextsWidth * Math.max(Math.floor(viewWidth / mapWithTextsWidth), 1);

        let shift = 0;
        if (isActiveWidget) {
          shift = Math.max((visibleMaps - viewWidth) / 2, 0);
          this.setState({ shift });
        } else {
          shift = this.state.shift;
          this.setState({ shift: 0 });
        }

        const paddingShift = Math.max((viewWidth - visibleMaps) / 2, 0);
        cont.style.paddingLeft = `${paddingShift}px`;
        cont.style.paddingRight = `${paddingShift + widgetWidth}px`;

        cont.scrollLeft = isActiveWidget
          ? cont.scrollLeft + shift
          : cont.scrollLeft - shift;
      }, 100)
    }
  }

  updateModes = (maps: IMap[]) => {
    this._modes = maps.map(map => map.mode);
  };

  changeHandler = (key: string, value: any) => {
    if (key === 'highlights' && this._keyScroll) { return }

    this.props.onChanged(key, value);
  };

  scrollToTablet = (mode: CircleMode, changeAspectsTableMode = false, immediate = false) => {
    const index = this.props.maps.findIndex(m => m.mode === mode);

    const rect = window.document.getElementById(this.props.maps[index]?.mode)?.getBoundingClientRect();
    const cont = this._container.current;
    if (rect && cont) {
      const widgetWidth = !!this.props.isActiveWidgets!.length
        ? document.getElementById('widget-container')?.clientWidth || 0
        : 0;
      const sideBarWidth = document.getElementById('side-bar-container')?.clientWidth || 0;
      const horizonCorrector = isTablet && widgetWidth && window.orientation === 0 ? 150 : 0;
      const containerLeft = this._container.current!.scrollLeft;
      const containerTop = this._container.current!.scrollTop;
      const { screenWidth, screenHeight } = this.getViewportSize();
      const targetLeft = sideBarWidth + horizonCorrector + (screenWidth - widgetWidth - rect.width - sideBarWidth) / 2;
      const targetTop = (screenHeight - rect.height) / 2;

      if (immediate) {
        cont.scrollLeft = containerLeft + (rect.left - targetLeft);
        cont.scrollTop = containerTop + (rect.top - targetTop);
      } else {
        !isTablet && this._container.current!.scrollTo({
          left: containerLeft + (rect.left - targetLeft),
          top: containerTop + (rect.top - targetTop),
          behavior: 'smooth',
        });
      }
    }

    if (this.props.currentMode !== mode) {
      this.props.onChanged('current-mode-scroll', mode);
      if (changeAspectsTableMode) {
        this.props.onChanged('aspects-table-mode', mode);
      }
    }
  }

  scrollTo = (mode: CircleMode, changeAspectsTableMode = false, immediate = false) => {
    //this.props.maps.forEach(map => console.log(window.document.getElementById(map.mode)?.getBoundingClientRect()))
    const index = this.props.maps.findIndex(m => m.mode === mode);

    this._keyScroll = true;

    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
      this._timeoutId = null;
    }

    if (this.props.currentMode !== mode) {
      this.props.onChanged('current-mode-scroll', mode);
      if (changeAspectsTableMode) {
        this.props.onChanged('aspects-table-mode', mode);
      }
    }

    const containerLeft = this._container.current!.scrollLeft;

    const currentDiameter = this._map.current?.height.baseVal.value ? this._map.current?.height.baseVal.value : 0;
    const currentGap = currentDiameter * 0.333;
    const destination = index * (currentDiameter + currentGap) - this._container.current!.scrollLeft;

    this._timeoutId = setTimeout(() => {
      this._keyScroll = false;
    }, 1000) as unknown as number;

    this._container.current!.scrollTo({
      left: containerLeft + destination,
      behavior: 'smooth'
    });
  };

  onScroll = (ev: any) => {
    const index = Math.round(ev.currentTarget.scrollLeft / ((this.personalization.circle.radius.circle * 2 + this.personalization.circle.gap) * this.props.scale));
    //const currentMode = this.props.maps[index].mode;
    const cont = this._container.current;
    if (!cont) {
      return;
    }
    this._prevScroll = {
      left: cont.scrollLeft,
      top: cont.scrollTop,
      width: cont.scrollWidth,
      height: cont.scrollHeight
    };

    // if (this.props.currentMode !== currentMode && !this._keyScroll) {
    //   this.props.onChanged('current-mode-scroll', currentMode);
    // }
  };

  componentDidMount() {
    dispatcher.add('scrollToMode', this.scrollFn);

    this._container.current!.addEventListener('wheel', this.onWheel, false);
    this._container.current!.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);
    window.addEventListener('click', this.onClick);
    if (isTablet) {
      window.addEventListener('resize', this.handleResize);
      window.addEventListener('orientationchange', () => {
      })
      this.handleResize();
    }

    // Safari gesture
    this._container.current!.addEventListener('gesturestart', this.onGesturePrevent, false);
    this._container.current!.addEventListener('gesturechange', this.onGestureChange, false);
    this._container.current!.addEventListener('gestureend', this.onGestureEnd, false);
    window.addEventListener('gesturechange', this.onGesturePrevent, false);
    this.props.countMapScale(this._container);

    this.centerMaps();


    window.addEventListener('sidebar', this.onSidebar);

    this.handleResize()
  }

  componentWillUnmount() {
    dispatcher.remove('scrollToMode', this.scrollFn);

    this._container.current!.removeEventListener('wheel', this.onWheel, false);
    this._container.current!.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp);
    window.removeEventListener('click', this.onClick);
    if (isTablet) {
      window.removeEventListener('resize', this.handleResize);
      window.removeEventListener('orientationchange', this.handleResize)
    }

    // Safari gesture
    this._container.current!.removeEventListener('gesturestart', this.onGesturePrevent, false);
    this._container.current!.removeEventListener('gesturechange', this.onGestureChange, false);
    this._container.current!.removeEventListener('gestureend', this.onGestureEnd, false);
    window.removeEventListener('gesturechange', this.onGesturePrevent, false);
    window.removeEventListener('sidebar', this.onSidebar);
  }

  scrollFn = (mode: CircleMode) => {
    isTablet
      ? this.scrollToTablet(mode, true)
      : this.scrollTo(mode, true);
  }

  onSidebar() {
    () => {
      setTimeout(() => {
        this.handleResize()
      }, 250)
    }
  }

  onKeyDown = (ev: KeyboardEvent) => {
    this._keyPressed = true
    if (ev.keyCode >= 49 && ev.keyCode <= 57 && !ev.altKey && !ev.repeat) {
      const activeModesKeys = getActiveModesKeys(this.props.auxModes, this._modes);

      if (!isPrognostics(this._modes) && !isSynastry(this._modes) && !isRelocation(this._modes)) return;

      const index = ev.keyCode - 49;
      const mode = isSynastry(this._modes) ? this.props.synastryModes[index] :
        (isRelocation(this._modes) ? this.props.relocationModes[index] : this.props.prognosticsModes[index]);

      if (checkActiveInput() || !this.props.onModeChanged || !mode) return;

      ev.preventDefault();

      const scrollFn = isTablet ? this.scrollToTablet : this.scrollTo;

      if (this._modes.includes(mode)) {
        const newModes = this._modes.filter(md => md !== mode);
        if (ev.ctrlKey && newModes.length) {
          this.props.onModeChanged(newModes)
        } else {
          scrollFn(mode, true);
        }
      } else {
        this.props.onModeChanged(ev.ctrlKey ? mode : [mode]);
        setTimeout(() => {
          this.scrollFn(mode);
        }, 0);
      }

    } else if (ev.keyCode === 32 && !checkActiveInput()) {
      ev.preventDefault();
      this._spacePressed = true;
    }
  };

  onKeyUp = (ev: KeyboardEvent): void => {
    this._keyPressed = false
    if (ev.keyCode === 32 && !checkActiveInput()) {
      ev.preventDefault();
      this._spacePressed = false;
    }
  };

  onMouseMove = (ev: MouseEvent): void => {
    if (this._moving) {
      this._container.current && this._container.current.scrollBy(
        -ev.movementX / window.devicePixelRatio,
        -ev.movementY / window.devicePixelRatio
      );
      this.onScroll(ev);
    }
  };

  onMouseDown = (ev: React.MouseEvent): void => {
    if (ev.buttons === 4 || this._spacePressed && ev.buttons === 1) {
      ev.preventDefault();
      this._moving = true;
    }
  };

  onMouseUp = (): void => {
    this._moving = false;
    this._scrolling = false;
  };

  onGesturePrevent = (ev: any) => {
    ev.preventDefault();
    this.setState({
      gestureChangeLock: false,
    });
  };

  onGestureEnd = (ev: any) => {
    ev.preventDefault();

    this.setState({
      gestureRelativeScale: this.props.scale,
      gestureChangeLock: true,
    });
  };

  onGestureChange = _.throttle((ev: any) => {
    ev.preventDefault();
    if (this.state.gestureChangeLock) {
      return;
    }

    const {
      pageX,
      pageY,
      scale,
    } = ev;

    const delta = this.personalization.circle.scale.step * (scale < 1 ? 1 : -1);

    const scaleState = fitToRange(
      ev.scale * this.state.gestureRelativeScale,
      this.personalization.circle.scale.min,
      this.personalization.circle.scale.max
    )

    if (scaleState === this.props.scale) return;
    const cont = this._container.current!;

    if (isTablet) {
      this._prevScroll = {
        left: ((this._prevScroll.left || cont.scrollLeft) + pageX) * (scaleState / this.props.scale) - pageX,
        top: ((this._prevScroll.top || cont.scrollTop) + pageY) * (scaleState / this.props.scale) - pageY,
        width: cont.scrollWidth,
        height: cont.scrollHeight
      };

      cont.scrollLeft = this._prevScroll.left;
      cont.scrollTop = this._prevScroll.top;
    } else {

      const rect = cont.getBoundingClientRect();

      const px = pageX - rect.x - rect.width / 2;
      const py = pageY - rect.y - rect.height / 2;

      this._zoomPoint = {
        x: -px * delta,
        y: -py * delta
      };

      this._prevScroll = {
        left: cont.scrollLeft,
        top: cont.scrollTop,
        width: cont.scrollWidth,
        height: cont.scrollHeight
      };
    }

    this.props.setScale(scaleState);
  }, 16);

  onWheel = (ev: MouseEvent): void => {
    if (ev.shiftKey) this.onScroll(ev);

    if (ev.ctrlKey || ev.metaKey) {
      ev.preventDefault();
      const mapCont = this._map?.current;
      const mapRect = mapCont
        ? mapCont.getBoundingClientRect()
        : null;

      // FIXME:
      // @ts-ignore
      const { x, y, deltaY } = ev;

      const delta = isTablet
        ? deltaY / (mapRect?.height || 280)
        : this.personalization.circle.scale.step * Math.sign(deltaY);

      const scale = fitToRange(
        this.props.scale - delta,
        this.personalization.circle.scale.min,
        this.personalization.circle.scale.max
      );

      if (scale === this.props.scale) return;

      if (!this._container.current) return;

      const cont = this._container.current;
      const rect = cont.getBoundingClientRect();

      const px = x - rect.x - rect.width / 2;
      const py = y - rect.y - rect.height / 2;

      if (isTablet) {
        this._prevScroll = {
          left: ((this._prevScroll.left || cont.scrollLeft) + x) * (scale / this.props.scale) - x,
          top: ((this._prevScroll.top || cont.scrollTop) + y) * (scale / this.props.scale) - y,
          width: cont.scrollWidth,
          height: cont.scrollHeight
        };

        cont.scrollLeft = this._prevScroll.left;
        cont.scrollTop = this._prevScroll.top;
      } else {
        this._zoomPoint = {
          x: -px * delta,
          y: -py * delta,
        };

        this._prevScroll = {
          left: cont.scrollLeft,
          top: cont.scrollTop,
          width: cont.scrollWidth,
          height: cont.scrollHeight
        };
      }

      this.props.setScale(scale)
    }
  };

  onScrollMouseDown = () => {
    this._scrolling = true;
  };

  onClick = (ev: MouseEvent) => {
    if (this.props.currentMode !== 'natal' && this.props.natal && !this._keyPressed) {
      this.props.onHold('natal', false)
    }
  }

  getViewportSize = () => ({
    screenWidth: window.innerWidth || screen.width,
    screenHeight: window.innerHeight || screen.height,
  });

  render() {
    debugRender && console.log('Maps');

    const { maps, strongs, natal } = this.props;
    const { radius: { circle: radius }, gap } = store.settings.personalization.current.circle;
    const diameter = ((radius) * 2);
    const mapsWidth = (maps.length * diameter) + ((maps.length - 1) * gap);

    const k = parseFloat(getComputedStyle(document.documentElement).fontSize) / 16;

    const horar = maps.find(m => m.mode === 'horar');
    const soul = maps.find(m => m.mode === 'soul');

    const { compatibilityTransits } = store.instruments.mapIndicatorCurrent;

    return (
      <>
        <Container
          ref={this._container}
          onMouseDown={this.onMouseDown}
          onScroll={this.onScroll}
          className="maps-for-print"
          id={'maps-container'}
        >
          {maps.length
            ? <MapsWrapper
              className='maps-container'
              style={{
                height: (diameter + gap) * this.props.scale * this.props.finalScale,
                width: (mapsWidth + gap) * this.props.scale * this.props.finalScale,
              }}
            >

              <MapIndicatorBlock
                margin={((diameter + gap) * this.props.scale * this.props.finalScale) * 0.75}
                style={{
                  width: (mapsWidth + gap) * this.props.scale * this.props.finalScale,
                }}
              >
                {Boolean(!horar && !soul) && maps.map((map, index) => {
                  const top = this.props.scale > 1 ? 160 * this.props.scale : 160 / this.props.scale
                  const getCompensation = () => {
                    if (isCompatibility(this.props.currentMode as CircleMode)) {
                      return -150 / index;
                    }

                    if (index > 1) {
                      if (index > 4) {
                        return 20 * index;
                      } else {
                        return 10 * index;
                      }
                    } else {
                      return -100;
                    }
                  }
                  const width = diameter * this.props.scale * this.props.finalScale;

                  return (
                    <MapIndicator
                      key={`map_indicator_${index}`}
                      // @ts-ignore
                      form={this.props.form}
                      map={map}
                      mapIndex={index}
                      partners={this.props.partners!}
                      width={width}
                      currentMode={map.mode}
                      onModeChanged={this.props.onModeChanged!}
                    />
                  )
                })}
              </MapIndicatorBlock>

              <MapSvg
                ref={this._map}
                viewBox={`0 0 ${mapsWidth} ${diameter}`}
                shapeRendering="geometricPrecision"
                overflow="visible"
                style={{
                  height: diameter * this.props.scale * this.props.finalScale,
                  width: mapsWidth * this.props.scale * this.props.finalScale,
                }}
              >
                <defs>
                  <pattern id="circle-star1" x="0" y="0" width="100%" height="100%" patternTransform="translate(-2, -2)">
                    <image href={Theme.current === 'dark' ? StarSvgWhite : StarSvgDark} x="0" y="0"></image>
                  </pattern>
                  <pattern id="circle-star2" x="0" y="0" width="100%" height="100%" patternTransform="translate(-2, -2)">
                    <image href={Theme.current === 'dark' ? Star2SvgWhite : Star2SvgDark} x="0" y="0"></image>
                  </pattern>
                  <pattern id="circle-star3" x="0" y="0" width="100%" height="100%" patternTransform="translate(-2, -2)">
                    <image href={Theme.current === 'dark' ? Star3SvgWhite : Star3SvgDark} x="0" y="0"></image>
                  </pattern>
                </defs>

                {maps.map((m: IMap, i) => {
                  const pinnedAspects: IHighlight[] = [];

                  if (m.mode === this.props.widgetMode) {
                    this.props.pinnedAspects.map(id =>
                      highlightAspect(m.mode, m.aspects[id], hasExt(m.mode), false).forEach(h => pinnedAspects.push(h))
                    );
                  }

                  let synastryTransitAspectsMode2 = compatibilityTransits[m.mode]?.transitMode === ECompatibilityTransitMode.MODE_2
                    ? [...(m.transitPartnerAspects || []), ...(m.transitNatalAspects || [])]
                    : [];


                  // console.log('synastryTransitAspectsMode2 - ', synastryTransitAspectsMode2)

                  const synastryTransitAspectsMode2Highlights = synastryTransitAspectsMode2.reduce((acc, aspect) => {
                    return acc.concat(highlightAspect(m.mode, aspect));
                  }, [] as IHighlight[]);



                  const x = (diameter + gap) * i - diameter / 10;
                  const y = -diameter / 12;

                  return <React.Fragment key={m.mode}>
                    <Circle
                      limitedAccess={store.settings.user.isLimitedAccess}
                      limitedAccessAction={this.props.limitedAccessAction}
                      key={`map_${i}`}
                      mode={m.mode}
                      x={radius + (diameter + gap) * i}
                      y={radius}
                      r={radius}
                      houses={m.houses}
                      fixedStars={m.fixedStars || []}
                      //@ts-ignore
                      showFixedStars={m.showFixedStars}
                      objects={m.objects}
                      housesExt={m.housesExt || []}
                      housesOuter={m.housesOuter!}
                      objectsExt={m.objectsExt || []}
                      objectsOuter={m.objectsOuter!}
                      soulObjects={
                        // FIXME:
                        // @ts-ignore
                        m.soulObjects || []
                      }
                      chains={m.chains || []}
                      aspects={m.aspects}
                      transitNatalAspects={m.transitNatalAspects || []}
                      transitPartnerAspects={m.transitPartnerAspects || []}
                      strongs={strongs}
                      highlights={{
                        items: synastryTransitAspectsMode2Highlights.length
                          ? synastryTransitAspectsMode2Highlights
                          : [
                            ...(this.props.highlights?.items?.filter(h => h.map === m.mode) || []),
                            ...pinnedAspects
                          ],
                        aspects: this.props.highlights.aspects
                      }}
                      personalization={store.settings.personalization.current}
                      computedRadius={store.settings.personalization.computedRadius}
                      onChanged={(key, value) => this.changeHandler(key, value)}
                      soulStrongs={m.mode === 'soul' && m.strongs ? m.strongs : []}
                      minorAspectsMode={this.props.minorAspectsMode}
                      t={this.props.t}
                    />
                  </React.Fragment>;
                })
                }
              </MapSvg>

            </MapsWrapper>
            : <MapPreloader mapSize={mapsWidth * this.props.scale * this.props.finalScale} />
          }

          <span
            ref={this._scroll}
            onMouseDown={this.onScrollMouseDown}
          />
        </Container>

        {horar && !isTablet && <Horar isActiveWidgets={this.props.isActiveWidgets} objects={horar.objects} />}

        {natal &&
          <>
            <NatalBackground />

            <NatalWrapper className='natal-wrapper'>
              <svg
                viewBox={`0 0 ${diameter} ${diameter}`}
                style={{
                  width: `${diameter * this.props.scale / this._remC}rem`,
                  height: `${diameter * this.props.scale / this._remC}rem`,
                }}
                shapeRendering="geometricPrecision"
                overflow="visible"
              >
                <Circle
                  limitedAccess={store.settings.user.isLimitedAccess}
                  limitedAccessAction={this.props.limitedAccessAction}
                  mode={'natal'}
                  x={radius}
                  y={radius}
                  r={radius}
                  fixedStars={natal.fixedStars || []}
                  //@ts-ignore
                  showFixedStars={natal.showFixedStars}
                  houses={natal.houses}
                  objects={natal.objects}
                  housesExt={[]}
                  objectsExt={[]}
                  objectsOuter={[]}
                  housesOuter={[]}
                  aspects={natal.aspects}
                  strongs={strongs}
                  soulObjects={[]}
                  soulStrongs={[]}
                  chains={[]}
                  onChanged={() => { }}
                  personalization={this.personalization}
                  computedRadius={this.computedRadius}
                  minorAspectsMode={this.props.minorAspectsMode}
                />
              </svg>
            </NatalWrapper>
          </>
        }

        <MapInfoPopup id="map-info-popup">
        </MapInfoPopup>
      </>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: ${isTablet ? 'initial' : 'visible'};
  box-sizing: border-box;

  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media print {
    font-size: 1.225rem !important;
    overflow: visible !important;
  }
`;

const MapIndicatorBlock = styled.div<{ margin?: number }>`
  position: absolute;
  top: 5rem;
  width: 100%;
  display: flex;
	align-items: center;
  justify-content: space-around;

  flex-direction: row;
  margin-left: ${(p) => (p.margin ? `-${p.margin}px` : '-5rem')};
`;

const MapTitleLocal = styled.text`
  display: inline-flex;
  fill: var(--text-third);
  width: fit-content;
  font-size: 1.125rem;
  /* left: 3rem; */
`

const MapTitle = styled.text`
  display: inline-flex;
  fill: var(--text-primary);
  width: fit-content;
  font-size: 1.125rem;
  /* left: 3rem; */
`

const MapsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  position: relative;

  @media print {
    align-items: flex-start;
  }
`;

const NatalBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  background-color: var(--workspace-background);
  opacity: 0.8;

  z-index: 3;
`;

const NatalWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 3;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const MapInfoPopup = styled.div`
  position: fixed;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  color: var(--text-primary);
  padding: 0.5em;
  border-radius: 0.25em;
  border: 1px solid var(--text-third);
  background-color: var(--background);
  font-size: 0.875rem;
  pointer-events: none;

  animation: ${fadeIn} 0.25s ease-in-out;
  white-space: nowrap;

  width: fit-content;

  & > div + div {
    margin-top: 0.2rem;
  }
`;

const MapSvg = styled.svg`
  /*@media (max-width: 1600px) {
    transform: scale(0.8);
  }
  @media (max-width: 1300px) {
    transform: scale(0.65);
  }
  @media (max-width: 1000px) {
    transform: scale(0.5);
  }*/
`;
