import React, { useEffect, useState } from 'react';
import { IWidgetsItem} from "../../api";
import {widgets} from "./Widgets";
import Attw from "../../components/AdvancedTooltipWrapper";
import {ReactMemo} from "../../hooks";
import styled, {css} from "styled-components";
import InstrumentButton from 'src/components/InstrumentButton';
import { useDeviceDetect } from 'src/hooks';
import Select from 'src/ui/Select';
import { CircleMode } from 'src/libs';

import store from 'src/store';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { history } from 'src/router'

interface IWidgetsButtonsProps {
  widgets: IWidgetsItem[]
  isLocked: boolean
  modes: CircleMode[]
  onClick: (id: string) => void
  onReplace: (from: string, to: string) => void
  limitedAccess: boolean
  limitedAccessAction: () => void; 
  _formId: number;
}

export default observer(function WidgetsButtons(props: IWidgetsButtonsProps) {
  const match = history.location.search.match(/id=(\d+)/);
  const formId = match ? +match[1] : 0;
  const [openTablePrediction, setOpenTablePrediction] = React.useState<boolean>((store.instruments.openTablePrediction[formId])?.value ?? true);
  const [startCalcTablePrediction, setStartCalcTablePrediction] = React.useState<boolean>(false);
  const [_formId, set_formId] = React.useState<number>((formId === 0 || formId === -1) ? props._formId : formId);

  useEffect(() => {
    if(formId === 0 && props._formId) {
      set_formId(props._formId)
    } else {
      set_formId(formId)
    }
  }, [props._formId, formId])

    useEffect(() => {
      if((store.instruments.openTablePrediction[_formId])?.value === false) {
        setOpenTablePrediction(false)
      } else {
        setOpenTablePrediction(true)
      }  
      }, [(store.instruments.openTablePrediction[_formId])?.value])

      useEffect(() => {
        if(Object.keys(toJS(store.instruments.tablePredictionSettings[_formId]?.result ?? {})).length > 0) {
          setStartCalcTablePrediction(true)
        } 
        }, [_formId, (store.instruments.tablePredictionSettings[_formId] ?? {}).result, (store.instruments.tablePredictionSettings[_formId]?.result)])

      useEffect(() => {
        if (store.instruments.tablePredictionSettings[_formId]?.result !== null && store.instruments.tablePredictionSettings[_formId]?.result !== undefined) {
          if (Object.keys(toJS(store.instruments.tablePredictionSettings[_formId]?.result)).length > 0) {
            setStartCalcTablePrediction(true)
          }
        }
        }, [(store.instruments.tablePredictionSettings[_formId])?.result])


  const { isLimitedAccess } = store.settings.user;

  const { isTablet } = useDeviceDetect();
  const [screenWidth, setScreenWidth] = useState(screen.width || window.innerWidth);

  const btnWidgetsAmounth = screenWidth < 960 ? 0 : 3;
  const btnWidgets = isTablet ? props.widgets.slice(props.widgets.length - btnWidgetsAmounth) : props.widgets;
  const selectWidgets = isTablet ? props.widgets.slice(0, props.widgets.length - btnWidgetsAmounth) : null;

  const onScreenSizeChanged = () => setScreenWidth(screen.width || window.innerWidth);
  
  useEffect(() => {
    window.addEventListener('resize', onScreenSizeChanged);

    return () => window.removeEventListener('resize', onScreenSizeChanged);
  }, []);

  const widgetsButtons = <>
    <Container isEmptyWidgets={selectWidgets?.length === 0}>
      { selectWidgets
          ? <Select
              isTop={true}
              isLeft={true}
              options={selectWidgets.map((w, i) => ({
                id: i,
                Icon: widgets[w.id].icon,
                title: widgets[w.id].title,
                rightIcon: () => null,
              }))}
              onSelect={(id) => {
                props.onClick(selectWidgets[id].id)
              }}
              hideKeyIcon
              hideSelected
              selected={props.widgets
                .map((w, i) => w.isActive ? i : -1)
                .filter(id => id >= 0)
              }
              closeOnClickOutside={true}
            />
          : null
      }
    </Container>
    <Container isEmptyWidgets={btnWidgets?.length === 0}>
      {
        btnWidgets.map((w, i, array) => {
          if (
            !widgets[w.id] ||
            props.isLocked && ['devplan', 'autoprediction', 'rectification'].includes(w.id)
          ) {
            return null;
          }

          const align = array.length - i <= 3 ? 'right' : 'left'
          //@ts-ignore
          const limitedAccess = isLimitedAccess && !w.showWithAccessLimit;
    
          return (
            <Attw
              text={widgets[w.id].title}
              align={align}
              key={`ttw_btn_wgt_${w.id}`}
            >
              <InstrumentButton
                id={w.id}
                key={`btn_wgt_${w.id}`}
                isActive={!!w.isActive}
                // modes={props.modes} // FIXME: !!!
                activeStyle="color"
                onClick={() => {
                  if (!limitedAccess) {
                    if(w.id === 'table-prediction') {
                      if(startCalcTablePrediction) {
                        store.instruments.setOpenTablePrediction(_formId, !openTablePrediction)
                      } else {
                        props.onClick(w.id)
                        store.instruments.setOpenTablePredictionSettings(_formId, true)
                      }
                    } else {
                      props.onClick(w.id)
                    }
                    
                  } else {
                    props.limitedAccessAction?.()
                  }
                }}
                onReplace={props.onReplace}
                isLocked={props.isLocked}
                icon={widgets[w.id].icon}
                //@ts-ignore
                disabled={limitedAccess}
              />
            </Attw>
          )
        })
      }
    </Container>
  </>

  return widgetsButtons
})

const Container = styled.div<{isEmptyWidgets: boolean}>`
  margin: 0 0.5rem;
  
  & > * {
    margin-left: 0.5rem;
  }

  ${props => props.isEmptyWidgets && css`
  margin: 0;
  `}
`;


